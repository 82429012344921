<template>
  <div class="page-list-content admin_container_default">
    <div class="page-list-table">
      <div class="mb-2 row p-0 admin_header">
        <div class="d-flex p-0 admin_title">
          <div class="text-truncate-mess header-font font-weight-bold">
            登録メルマガ管理
          </div>
        </div>
        <div class="row-input admin_center">
          <CInput
            class="form-input m-0"
            style="width: 100%"
            placeholder="検索"
            v-model="keywords"
            v-on:keyup="getListMailMegas"
          />
        </div>
        <div class="tex-left text-md-right text-sm-right admin_right">
          <button v-on:click="createMailMega()" class="button-create-page">
            メルマガ同期作成
          </button>
          <button v-on:click="gotoDashboard()" class="button-back-dashboard">
            戻る
          </button>
        </div>
      </div>
      <!-- <div class="d-flex justify-content-center mt-4 mb-4">
        <b-button
          v-on:click.prevent="getListMailMegas()"
          variant="info"
          class="mr-4"
          >絞り込み検索</b-button
        >
        <b-button
          variant="secondary"
          class="mr-4"
          v-on:click="resetFieldSearch()"
          >リセット</b-button
        >
      </div> -->
      <Tables
        :items="listMailMega.data"
        :fields="fields"
        :itemsPerPage="50"
        class="cpx-15"
      >
        <template v-slot:page_content="{ item }">
          <td v-html="item.page_content"></td>
        </template>
        <template v-slot:category_name="{ item }">
          <td style="min-width: 200px; width: 20%" class="py-2">
            <div class="text-truncate-mess">
              {{ item.category_name }}
            </div>
          </td>
        </template>
        <template v-slot:category_id="{ item }">
          <td style="min-width: 200px; width: 20%" class="py-2">
            <div class="text-truncate-mess">
              {{ item.category_id }}
            </div>
          </td>
        </template>
        <template v-slot:id="{ index }">
          <td>{{ listMailMega.current_page * limit - limit + index + 1 }}</td>
        </template>
        <template v-slot:updated_at="{ item }">
          <td>
            {{
              new Date(item.updated_at).toLocaleString("ja-JP", {
                timeZone: "Asia/Tokyo",
              })
            }}
          </td>
        </template>
        <template v-slot:action="{ item }">
          <td class="py-2 pl-2 pr-1 d-flex">
            <router-link
              style="color: black"
              :to="{
                name: $route.params.shopId
                  ? 'Edit Mail Mega'
                  : 'Edit Mail Mega domain',
                params: {
                  shopId: $route.params.shopId,
                  id: item.id,
                },
              }"
            >
              <CButton
                class="mx-1 btn-other"
                square
                size="sm"
                v-b-modal.modal-comfirm
              >
                編集
              </CButton>
            </router-link>
            <CButton
              class="mx-1 text-nowrap btn-del"
              square
              v-on:click="showConfirm(item.id)"
              variant="outline"
              size="sm"
              v-b-modal.delete-modal-page
            >
              削除
            </CButton>
          </td>
        </template>
      </Tables>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listMailMega.total"
      >
        <p class="font-weight-bold">
          {{ listMailMega.total }}件中{{ listMailMega.data.length }}件を表示
        </p>
      </div>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listMailMega.total"
      >
        <CPagination
          v-if="listMailMega.total"
          :activePage.sync="page"
          :pages="listMailMega.last_page"
          align="center"
        />
        <!-- <div class="custom-selectLimitPage">
          <b-form-select
            v-model="limit"
            tag-placeholder="Add this as new category"
            :options="listLimitRecordPage"
            @change="changeLimitPage()"
          ></b-form-select>
        </div> -->
      </div>
    </div>
    <div>
      <b-modal id="delete-modal-page" hide-header hide-footer>
        <div class="d-block text-center">
          <h5>
            削除後、ご利用頂けなくなります。ご注意の上、削除をお願い致します。（復元不可となります。ご留意くださいませ）
          </h5>
        </div>
        <div class="d-flex justify-content-center">
          <b-button
            class="mx-2 mt-3 btn btn-danger"
            block
            @click="removeMailMega(dataModal)"
            >削除</b-button
          >
          <b-button
            class="mx-2 mt-3 btn btn-secondary"
            block
            @click="$bvModal.hide('delete-modal-page')"
          >
            キャンセル
          </b-button>
        </div>
      </b-modal>
    </div>
    <div>
      <b-modal id="modal-showContent" hide-header hide-footer>
        <div class="d-flex justify-content-center"></div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Tables from "../../components/common/Tables";
import { tableFields } from "../../utils/table-fields.js";
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";

export default {
  name: "ListMailMega",
  components: {
    Tables,
  },
  data() {
    return {
      fields: tableFields.MAIL_MEGA,
      listChecked: [],
      dataModal: "",
      listLimitRecordPage: [
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 200, text: "200" },
      ],
      limit: 50,
      page: 1,
      // shop_id: null,
      keywords: "",
      shop_id: localStorage.getItem(Constants.SHOP_ID),
    };
  },
  created() {
    this.getListMailMegas(1);
  },
  computed: {
    ...mapGetters(["listMailMega", "message", "success", "error"]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
        this.getListMailMegas(this.page);
      }
    },
    error() {
      if (this.error) {
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    listMailMega() {
      this.page = this.listMailMega.current_page;
    },
    page() {
      this.getListMailMegas(this.page);
    },
  },
  methods: {
    ...mapActions({ getlistMailMega: "getlistMailMega" }),
    ...mapActions({ deleteMailMega: "deleteMailMega" }),
    getListMailMegas(page) {
      if (page === undefined) {
        page = this.page;
      }
      const formData = {
        page: page,
        data: {
          shop_id: this.shop_id,
          limit: this.limit,
          keywords: this.keywords,
        },
      };
      this.getlistMailMega(formData);
    },
    createMailMega() {
      this.$router.push({
        name: this.$route.params.shopId
          ? "Create Mail Mega"
          : "Create Mail Mega domain",
      });
    },
    showConfirm(id) {
      this.dataModal = id;
    },
    showConfirmContent(id) {
      this.dataModal = id;
    },
    async removeMailMega(id) {
      const data = {
        id: id,
        shop_id: this.shop_id,
      };
      const dataReturn = await this.$store.dispatch("deleteMailMega", data);
      if (dataReturn.success) {
        this.$toasted.success(dataReturn.message);
        this.$nextTick(() => {
          this.$bvModal.hide("delete-modal-page");
          this.getListMailMegas(this.page);
        });
      } else {
        this.$toasted.error(dataReturn.message);
      }
    },
    editCategory(id) {
      this.$router.push({
        name: this.$route.params.shopId
          ? "Edit Category"
          : "Edit Category domain",
        params: { id: id },
      });
    },
    changeLimitPage() {
      this.getListMailMegas(1);
    },
    gotoDashboard() {
      if (this.$route.params.shopId) {
        this.$router.push({ name: "Dashboard Admin" });
      } else {
        this.$router.push({ name: "Dashboard Admin domain" });
      }
    },
  },
};
</script>
<style scoped>
.page-list-content {
  padding: 40px 4% !important;
}
</style>
